/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

@import url('https://fonts.googleapis.com/css2?family=Anton&family=Barlow+Condensed:wght@300&display=swap');

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
  font-family: 'Anton', sans-serif;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.locations-wrapper{
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-holder{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  transition: height 2s;
}

.logo{
  z-index: 10;
  max-width: 500px;
  width: 90%;
}

.locations{
  position: absolute;
  width: 100%;
  transition-property: top;
  transition-timing-function: ease-in;
}

.location{
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: relative;
  padding: 0 15px; 
  box-sizing: border-box;
}

.location:before{
  content: '';
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.location .text{
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: opacity .5s;
}

.location .text h1{
  font-size: 2em;
  text-transform: uppercase;
  text-align: center;
  color:#7bc32d;
  font-family: 'Anton', sans-serif;
}

.location .text p{
  font-size: 1em;
  max-width: 700px;
  text-align: center;
  font-family: 'Barlow Condensed', sans-serif;
  margin-top: 30px;
}

.button{
  z-index: 10;
  position: relative;
  background: #7bc32d;
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 10px 50px;
  font-size: 30px;
  font-family: 'Anton', sans-serif;
  text-transform: uppercase;
}

.description{
  font-family: 'Anton', sans-serif;
  max-width: 520px;
  width: 100%;
  z-index: 10;
  color: #fff;
  opacity: 1;
  transition: all .5s;
  text-align: center;
  font-size: 2em;
  padding: 0 20px;
}

.hide{
  opacity: 0;
}

@media only screen and (min-width: 600px) {
  body{
    font-size: 30px;
  }
  .description{
    font-size: 1em;
  }
}